// extracted by mini-css-extract-plugin
export var advisors = "Advisors-module--advisors--QY5ZH";
export var blockOne = "Advisors-module--blockOne--QtTBY";
export var blockThree = "Advisors-module--blockThree--YeX8+";
export var blockTwo = "Advisors-module--blockTwo--oD9Ap";
export var blocks = "Advisors-module--blocks--wREr5";
export var box = "Advisors-module--box--ukPCM";
export var container = "Advisors-module--container--zGZWM";
export var header = "Advisors-module--header--xEdYz";
export var heading = "Advisors-module--heading--J3BiK";
export var icon = "Advisors-module--icon--AtHw+";
export var image = "Advisors-module--image--kIVJO";
export var linkButton = "Advisors-module--linkButton--1lVpA";
export var paragraph = "Advisors-module--paragraph--FJ8fx";